// Refactoring Done 2023

const SystemEnvironment = {
  production: {
    // abu dhabi
    // Propx@xeleration.net
    name: "production",
    server_address: 'https://apigateway.psi-crm.com',
    ws_address: 'wss://apigateway.psi-crm.com',
    server_name: 'apigateway.psi-crm.com',
    ActivityTypesCategoryId: 63385,
    MultiUnitsTemplateId: 1252,
    hotjarSiteId: 3891241,
    ContactDocsCategoryIds_EID : 63383,
    ContactDocsCategoryIds_KYC : 63384,
    OfferLetterTemplateId: {
      CommercialOfferLetter: 1283,
      ResidentialOfferLetter: 1281,
    },
    TenantOfferLetterTemplateId: {
      CommercialOfferLetter: 1287,
      ResidentialOfferLetter: 1288,
    },
    TenancyContractTemplateId: {
      CommercialTenancyContract: 1420,
      ResidentialTenancyContract: 1421,
    },
    TenantTenancyContractTemplateId: {
      CommercialTenancyContract: 1418,
      ResidentialTenancyContract: 1419,
    },
  },
  development: {
    name: "development",
    server_address: 'https://apigateway.dev.psi-crm.com',
    ws_address: 'wss://apigateway.dev.psi-crm.com',
    server_name: 'apigateway.dev.psi-crm.com',
    DisableRightClicking: false,
    ThemeBi: true,
    ActivityTypesCategoryId: 62190,
    hotjarSiteId: 3880259,
    ContactDocsCategoryIds_EID : 62246,
    ContactDocsCategoryIds_KYC : 62247,
    OfferLetterTemplateId: {
      CommercialOfferLetter: 2661,
      ResidentialOfferLetter: 2660,
    },
    TenantOfferLetterTemplateId: {
      CommercialOfferLetter: 2666,
      ResidentialOfferLetter: 2667,
    },
    TenancyContractTemplateId: {
      CommercialTenancyContract: 2689,
      ResidentialTenancyContract: 2690,
    },
    TenantTenancyContractTemplateId: {
      CommercialTenancyContract: 2684,
      ResidentialTenancyContract: 2685,
    },
  },
  dubaiCRMConfig: {
    name: "dubaiCRMConfig",
    // production for dubai
    // info@xeleration.net
    server_address: 'https://apigateway.dubai-crm.com',
    ws_address: 'wss://apigateway.dubai-crm.com',
    server_name: 'apigateway.dubai-crm.com',
    MultiUnitsTemplateId: 1222,
    hotjarSiteId: 3909956,
    ContactDocsCategoryIds_EID : 62357,
    ContactDocsCategoryIds_KYC : 62358,
    OfferLetterTemplateId: {
      CommercialOfferLetter: 1227,
      ResidentialOfferLetter: 1226,
    },
    TenantOfferLetterTemplateId: {
      CommercialOfferLetter: 1229,
      ResidentialOfferLetter: 1230,
    },
    TenancyContractTemplateId: {
      CommercialTenancyContract: 1337,
      ResidentialTenancyContract: 1338,
    },
    TenantTenancyContractTemplateId: {
      CommercialTenancyContract: 1335,
      ResidentialTenancyContract: 1336,
    },
  },
  dubai: {
    name: "dubai",
    server_address: 'https://apigateway.dubai.psi-crm.com',
    ws_address: 'wss://apigateway.dubai.psi-crm.com',
    server_name: 'apigateway.dubai.psi-crm.com',
    GetMyFormsReportsTypeReportsBuilder: 3189,
  },
  staging: {
    name: "staging",
    server_address: 'https://apigateway.stg.psi-crm.com',
    ws_address: 'wss://apigateway.stg.psi-crm.com',
    freshdesk_ws_id: 73000000915,
    server_name: 'apigateway.stg.psi-crm.com',
    MultiUnitsTemplateId: 1250,
    OfferLetterTemplateId: {
      CommercialOfferLetter: 1259,
      ResidentialOfferLetter: 1258,
    },
    TenantOfferLetterTemplateId: {
      CommercialOfferLetter: 1260,
      ResidentialOfferLetter: 1261,
    },
    TenancyContractTemplateId: {
      CommercialTenancyContract: 1264,
      ResidentialTenancyContract: 1263,
    },
    TenantTenancyContractTemplateId: {
      CommercialTenancyContract: 1266,
      ResidentialTenancyContract: 1265
    },
  },
  demo: {
    name: "demo",
    server_address: 'https://apigateway.demo.psi-crm.com',
    ws_address: 'wss://apigateway.demo.psi-crm.com',
    freshdesk_ws_id: 73000000915,
    server_name: 'apigateway.demo.psi-crm.com',
    GetMyFormsReportsTypeReportsBuilder: 3189,
  },
  assets: {
    name: "assets",
    server_address: 'https://apigateway.psiassets-crm.com',
    ws_address: 'wss://apigateway.psiassets-crm.com',
    freshdesk_ws_id: 73000000915,
    server_name: 'apigateway.psiassets-crm.com',
  },
};
const DefaultCommonBaseConfig = {
  organizationsId: '85154615-FBAE-412E-8F39-08D851A0A786',
  applicationId: '523cecec-696a-4c77-9f32-af8227a1b119',
  source: '4D50FC57-C8CB-415D-93FF-AB20A2F23619',
  ApiKey: '0jUt4NHaUOTb3fhfkk35QOOvO4IudrkG4NttIwnbz1wZeVwOHShEEqCeEdpWLopg',
  SendKey: '0jUt4NHaUOTb3fhfkk35QOOvO4IudrkG4NttIwnbz1wZeVwOHShEEqCeEdpWLopg',
  GoogleMapApiKey: 'AIzaSyCzLOjMxvVKsuTeNMxXQllDcJSMnsUPxuY',
  TinyMCE_ApiKey: '0m9n9euh0vwkbvh2rezjo7sca4u6rfavmk2kdkukeeo47zcg',
  timeZone: 'Asia/Dubai',
  freshdesk_ws_id: 73000001170,
  DisableRightClicking: true,
  MultiUnitsTemplateId: 1626,
  SalesUnitProposalTemplateId: 1062,
  LeasingUnitProposalTemplateId: 1063,
  ContactDocsCategoryIds_EID : 63383,
  ContactDocsCategoryIds_KYC : 63384,
  TaskNotificationTemplateId: 9,
  ContactDocsCategoryIds_Passport : 18099,
  ContactDocsCategoryIds_IDCard : 18098,
  GetMyFormsReportsTypeReportsBuilder: 3179,
  ActivityTypesCategoryId: 62360,
  version: '3.4.0'
};
const selectedEnvironment = 'production';
const environmentConfig = SystemEnvironment[selectedEnvironment];
const config = { ...DefaultCommonBaseConfig, ...environmentConfig };

export { config };

//         _______________________________________
//        |,---"-----------------------------"---,|
//        ||___    2024 bit....................  ||
//        ||====\ :HHHHHHHHHHHHHHHHHHHHHHHHHHH   ||
//        ||=====):H c> npm i #              H   ||                .--------.       ______
//        ||====/ :H    Propx CRM  Config    H   ||               /   o    /|     /\      \
//        ||"""   :H                         H   ||              /________/o|    / o \  o  \
//        ||CRM   :H                         H   ||              | o      | |   /   o\______\
//        ||      :HHHHHHHHHHHHHHHHHHHHHHHHHHH   ||              |    o   |o/   \o   /o     /
//        ||_____,___________MH____________,_____||              |      o |/     \ o/  o   /
//        |)_____)-----.| / Config |.------(_____(|              '--------'       \/_____o/
//      //"""""""|_____|=----------=|______|"""""""\
//     // _| _| _| _| _| _| _| _| _| _| _| _| _| _| \
//    // ___| _| _| _| _| _| _| _| _| _| _| _|  |  | \
//   |/ ___| _| _| _| _| _| _| _| _| _| _| _| ______| \
//   / __| _| _| _| _| _| _| _| _| _| _| _| _| _| ___| \
//  / _| _| _| _| ________________________| _| _| _| _| \
// |------"--------------------------------------"-------|
// `-----------------------------------------------------'

// ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒░▒░▓
// ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒░
// ░▒██▓▓▓██▓░░░░░░░░░░░▓███████████████▓░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▓████████████▓░░░░░░░
// ▓█▓░░░░░▒██░░░░░░░░░░░▒████████████████▒░░░░░░░░░░░░░░░░░░░░░░░░░░░▒█████████████▒░░░░░░░░
// ██▒░░░░░░██▒░░░░░░░░░░░░▓███████████████▓░░░░░░░░░░░░░░░░░░░░░░░░░▓████████████▓░░░░░░░░░░
// ██▒░░░░░░██▒░░░░░░░░░░░░░▓████████████████▒░░░░░░░░░░░░░░░░░░░░░░█████████████▒░░░░░░░░░░░
// ██▒░░░░░░▓█▒░░░░░░░░░░░░░░░▓███████████████▓░░░░░░░░░░░░░░░░░░░▓█████████████░░░░░░░░░░░░░
// ██▒░░░░░░░░░░░░░░░░░░░░░░░░░▓████████████████▒░░░░░░░░░░░░░░░░█████████████▓░░░░░░░░░░░░░░
// ████████████████░░░░░░░░░░░░░▒████████████████▓░░░░░░░░░░░░░▒█████████████▒░░░░░░░░░░░░░░░
// ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▓████████████████▒░░░░░░░░░░▓████████████▓░░░░░░░░░░░░░░░░░
// ░░░░▒▓▓▓▓▓▒▒░░░░░░░░░░░░░░░░░░░░▒████████████████▓░░░░░░░▒█████████████▒░░░░░░░░░░░░░░░░░░
// ░▒██▓▒▒░░▒▒▓█▓▒░░░░░░░░░░░░░░░░░░░▓████████████████▒░░░░▓█████████████▒░░░░░░░░░░░░░░░░░░░
// ▒█▓░░░░░░░░░░██▒░░░░░░░░░░░░░░░░░░░▒████████████████▓░░░▓███████████▓░░░░░░░░░░░░░░░░░░░░░
// ██▒░░░░░░░░░░▒██░░░░░░░░░░░░░░░░░░░░░▓████████████████▒░░▒█████████▒░░░░░░░░░░░░░░░░░░░░░░
// ██▒░░░░░░░░░░▒██░░░░░░░░░░░░░░░░░░░░░░▒████████████████▓░░░▓█████▓░░░░░░░░░░░░░░░░░░░░░░░░
// ██▒░░░░░░░░░░▒██░░░░░░░░░░░░░░░░░░░░░░░░█████████████████▒░░▒███▓░░░░░░░░░░░░░░░░░░░░░░░░░
// ▒██▒░░░░░░░░▒██▒░░░░░░░░░░░░░░░░░░░░░░░░░▓████████████████▓░░░▓▒░░░░░░░░░░░░░░░░░░░░░░░░░░
// ░░▓██▓▒▒▒▒▓██▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒█████████████████▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
// ░░░░░▒▒▒▒▒▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒█████████████████▓░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
// ░░░░░░░░░░░░░░░▓░░░░░░░░░░░░░░░░░░░░░░░░░▓█████████████████▒░░▒▒░░░░░░░░░░░░░░░░░░░░░░░░░░
// ░▓██▓▓▓▓█▓▒░▒▓█▓░░░░░░░░░░░░░░░░░░░░░░░▒█████████████████▓░░░▓██▓░░░░░░░░░░░░░░░░░░░░░░░░░
// ▓█▓░░░░░░████▒░░░░░░░░░░░░░░░░░░░░░░░░▓█████████████████▒░░▒██████░░░░░░░░░░░░░░░░░░░░░░░░
// ██▒░░░░░░▓█▒░░░░░░░░░░░░░░░░░░░░░░░░▒█████████████████▓░░░▓████████▒░░░░░░░░░░░░░░░░░░░░░░
// ██▒░░░░░░▓█▒░░░░░░░░░░░░░░░░░░░░░░░▓█████████████████▓░░▒███████████▓░░░░░░░░░░░░░░░░░░░░░
// ██▒░░░░░░▓█▒░░░░░░░░░░░░░░░░░░░░░░█████████████████▓░░░░██████████████▒░░░░░░░░░░░░░░░░░░░
// ██▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒█████████████████▒░░░░░░██████████████▓░░░░░░░░░░░░░░░░░░
// ████████████████░░░░░░░░░░░░░░░██████████████████░░░░░░░░░▓██████████████░░░░░░░░░░░░░░░░░
// ░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒█████████████████▓░░░░░░░░░░░▒██████████████▓░░░░░░░░░░░░░░░
// ░░▒▓▓██▓▓▒░░░░░░░░░░░░░░░░░░▓████████████████▓░░░░░░░░░░░░░░░▓██████████████░░░░░░░░░░░░░░
// ▒██▒░░░░▓█▓░░░░░░░░░░░░░░░▒█████████████████▓░░░░░░░░░░░░░░░░░▒██████████████▒░░░░░░░░░░░░
// ██▒░░░░░░██▒░░░░░░░░░░░░░▓█████████████████▒░░░░░░░░░░░░░░░░░░░░▓█████████████▓░░░░░░░░░░░
// ██▒░░░░░░██▒░░░░░░░░░░░▒█████████████████▓░░░░░░░░░░░░░░░░░░░░░░░▒██████████████▒░░░░░░░░░
// ██▒░░░░░░██▒░░░░░░░░░░▓█████████████████▒░░░░░░░░░░░░░░░░░░░░░░░░░░▓█████████████▓░░░░░░░░
// ██▒░░░░░░░░░░░░░░░░░▒█████████████████▓░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒██████████████▒░░░░░░
// ██▓▓▓▓▓▓▓▓▓▓▓▓▓▓░░░▓█████████████████▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░██████████████▓░░░░░

// /$$$$$$$   /$$$$$$  /$$$$$$
// | $$__  $$ /$$__  $$|_  $$_/
// | $$  \ $$| $$  \__/  | $$      | $$   | $$  /$$$$$$   /$$$$$$   /$$$$$$$ /$$  /$$$$$$  /$$$$$$$
// | $$$$$$$/|  $$$$$$   | $$      |  $$ / $$/ /$$__  $$ /$$__  $$ /$$_____/| $$ /$$__  $$| $$__  $$
// | $$____/  \____  $$  | $$       \  $$ $$/ | $$$$$$$$| $$  \__/|  $$$$$$ | $$| $$  \ $$| $$  \ $$
// | $$       /$$  \ $$  | $$        \  $$$/  | $$_____/| $$       \____  $$| $$| $$  | $$| $$  | $$
// | $$      |  $$$$$$/ /$$$$$$       \  $/   |  $$$$$$$| $$       /$$$$$$$/| $$|  $$$$$$/| $$  | $$
// |__/       \______/ |______/        \_/     \_______/|__/      |_______/ |__/ \______/ |__/  |__/
